import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import store, { setIsConnected, setUserName } from "../redux/maxStore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api"; // Import the custom Axios instance

function SignIn() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#90caf9" },
      secondary: { main: "#f48fb1" },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `body { background-color: #212121; color: #fff; }`,
      },
    },
  });

  const navigate = useNavigate();
  const [user, setUser] = useState<{ error: any; user: any }>({
    user: null,
    error: null,
  });

  const logIn = async (event: any) => {
    try {
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      const username: string = (data.get("username") as string) || "";
      const body = {
        user: {
          email: data.get("email"),
          password: data.get("password"),
          username,
        },
        rememberMe: data.get("remember"),
      };

      store.dispatch(setUserName(username));
      setUser((prev) => ({
        ...prev,
        user: { username },
      }));

      const isGuestSubmit = event.nativeEvent?.submitter?.id === "guestButton";
      if (isGuestSubmit) {
        localStorage.setItem("token", "guest");
        navigate("/chat");
      } else {
        // Use the custom Axios instance instead of fetch
        const response = await api.post("/auth/login", body);
        if (response.status === 200) {
          toast.success("Login successful!");
          const token = response.data.token;
          localStorage.setItem("token", token);
          store.dispatch(setIsConnected(true));
          document.title = "daniel wende";
          navigate("/chat");
        } else {
          toast.error("Invalid username or password");
        }
      }
    } catch (error) {
      console.error(error);
      user.error = error;
      toast.error("An error occurred during login");
    }
  };

  const [remember, setRemember] = React.useState(true);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      const isGuestSubmit = event.nativeEvent?.submitter?.id === "guestButton";
      if (isGuestSubmit) {
        localStorage.setItem("token", "guest");
      } else {
        await logIn(event);
      }
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  const handleGuest = (event: any) => {
    try {
      event.preventDefault();
      localStorage.setItem("token", "guest_token");
      store.dispatch(setUserName("guest"));
      navigate("/projects");
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
  }, []);

  return (
    <>
      <ToastContainer theme="dark" />
      <ThemeProvider theme={darkTheme}>
        <Container
          component="main"
          maxWidth="xs"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "150px auto auto auto",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              /> */}
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        value={remember}
                        onChange={(e) => setRemember(e.target.checked)}
                        color="primary"
                      />
                    }
                    name="remember"
                    id="remember"
                    label="Remember me"
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className="super-button-2">
                    <button type="submit" className="button">
                      Sign In
                    </button>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="super-button">
                    <button
                      id="guestButton"
                      className="button type1"
                      onClick={handleGuest}
                    ></button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default SignIn;

import { createSlice, PayloadAction, configureStore } from "@reduxjs/toolkit";

// Define the shape of the state
interface ChatState {
  channel: string;
  value: string[];
  isAuthenticated: boolean;
  isMute: boolean;
  isConnected: boolean;
  username: string;
}

// Function to load initial state from localStorage
const loadInitialState = (): ChatState => ({
  channel: "",
  value: [],
  isMute: false,
  isAuthenticated: false,
  isConnected: !!localStorage.getItem("token"),
  username: localStorage.getItem("username") || "",
});

// Create the slice
const ChatStore = createSlice({
  name: "chat",
  initialState: loadInitialState(),
  reducers: {
    addMessage: (state, action: PayloadAction<string>) => {
      state.value.push(action.payload);
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.isConnected = !!localStorage.getItem("token");
      localStorage.setItem("username", action.payload);
      state.username = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setMute: (state, action: PayloadAction<boolean>) => {
      state.isMute = action.payload;
    },
  },
});

export const {
  setIsConnected,
  addMessage,
  setUserName,
  setIsAuthenticated,
  setMute,
} = ChatStore.actions;

// Configure the store
const store = configureStore({
  reducer: ChatStore.reducer,
});

export default store;
